import React, { FunctionComponent } from "react"
import { Link, PageProps } from "gatsby"
import ArticleCard from "../components/ArticleCard"
import SEO from "../components/seo/seo"
import RichTextRenderer from "../components/rich-text-handler/rich-text-handler"
import "../assets/styles/page/docArticlePage.scss"
import Layout from "../components/layout"
import Tweetbutton from "../components/tweetbutton"
import Facebookshare from "../components/facebookshare"

interface ArticlepageContext {
  edge: {
    metaDescription: string;
    metaTitle: string;
    titleOfArticle: string;
    articleBody: { json: any };
    bannerUrl: { url: string };
    aboutAuthor: string;
    authorImage: { url: string };
    _id: string;
  };
  data: any[];
}

const Articlepage: FunctionComponent<PageProps<object, ArticlepageContext>> = ({ pageContext }) => {
  const { metaDescription, metaTitle, titleOfArticle, articleBody, bannerUrl, aboutAuthor, authorImage, _id } = pageContext.edge

  const articleArray = pageContext.data

  return (
    <div>
      <SEO title={titleOfArticle} description={metaTitle} image={bannerUrl.url} />
      <Layout>
        <div className="article--content--container">
          <div className="article--content--inner">
            <article className="main--article--content">
              <div className="article--banner--wrapper">
                <div
                  className="banner--image--wrapper"
                  style={{
                    backgroundImage: `url(${bannerUrl.url})`
                  }}
                ></div>
                <div className="banner--overlap--wrapper">
                  <div className="banner--overlap--text--inner">
                    <h2 className="banner--overlap--headtext">{titleOfArticle}</h2>
                    <p className="banner--overlap--subtext">{metaTitle}</p>
                    <p className="meta">{metaDescription}</p>
                  </div>
                </div>
              </div>
              <div className="article--main--content">
                <RichTextRenderer content={articleBody.json} />
              </div>
              <div className="share--article--media">
                <Facebookshare />
                <Tweetbutton titleOfArticle={titleOfArticle} />
              </div>
              <div className="article--author--details--wrapper">
                <div
                  className="author--image"
                  style={{
                    backgroundImage: `url(${authorImage.url})`
                  }}
                ></div>
                <div className="about--author">
                  <p>{aboutAuthor}</p>
                </div>
              </div>
            </article>
          </div>
          <div className="upnext--article--wrapper">
            <div id="next-text">
              <h2>Up Next</h2>
            </div>
            <div className="article--list">
              {articleArray
                .slice(0, 3)
                .filter((e: { _id: any }) => e._id !== _id)
                .map(
                  (articles: {
                    bannerUrl: any
                    titleOfArticle: any
                    metaTitle: any
                    metaDescription: any
                    _id: any
                    slug: any
                  }) => {
                    const { bannerUrl, titleOfArticle, metaTitle, metaDescription, _id, slug } = articles

                    return (
                      <Link to={`/doc/article/doc-article/${slug}`} className="artile--card--wrapper--2" key={_id}>
                        <ArticleCard
                          key={_id}
                          articleImage={bannerUrl.url}
                          articleHeadtext={titleOfArticle}
                          articleSubtext={metaTitle}
                          meta={metaDescription}
                        />
                      </Link>
                    )
                  }
                )}
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default Articlepage
